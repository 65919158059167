<template>
    <CardComponent
        :icon="cardIcon"
        :title="cardTitle"
        :link="cardLink"
        class="arrive-monitor"
    >
        <template v-slot:cardOptions>
            <slot name="cashOption"></slot>
        </template>
        <div class="arrive-monitor-main">
            <el-table
                class="store-monitor-table"
                :data="tradeMonitorData"
                height="3.4rem"
                style="width: 100%"
                v-loading="loading"
            >
                <el-table-column
                    min-width="140px"
                    :show-overflow-tooltip="true"
                    prop="skuTitle"
                    label="货品名称"
                >
                    <template slot-scope="scope">
                        <div class="sku-title">
                            <span
                                :class="{
                                    delay: scope.row.monitorStatus === 1
                                }"
                                >{{
                                    scope.row.monitorStatus | statusFilter
                                }}</span
                            >
                            <span>{{ scope.row.skuTitle }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="140px"
                    prop="warehouseName"
                    label="仓库"
                >
                </el-table-column>
                <el-table-column
                    min-width="100px"
                    prop="receiveTime"
                    label="预计到货时间"
                >
                </el-table-column>
            </el-table>
            <!-- <el-scrollbar style="height: 100%">
                <div
                    class="data-item"
                    v-for="(item, index) in cardData"
                    :key="index"
                >
                    <p class="sum-value">
                        <span>{{ item.monitorStatus | statusFilter }}</span>
                        <span>{{ item.skuTitle }}</span>
                    </p>
                    <p class="sum-title">
                        <span>{{ item.warehouseName }}</span>
                        <span>{{ item.receiveTime }}</span>
                    </p>
                </div>
            </el-scrollbar> -->
        </div>
        <!-- <Empty :showImg="false" v-else /> -->
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import tradeSvc from 'services/trade'
export default {
    name: 'CashCard',
    components: {
        CardComponent
    },
    props: {
        cardTitle: {
            type: String,
            default: '到货监控'
        },
        cardIcon: {
            type: String,
            default: 'iconfont icon-caiwuyunying'
        },
        cardLink: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            tradeMonitorData: []
        }
    },
    created() {
        this.getTradeMonitor()
    },
    methods: {
        // 到货监控
        async getTradeMonitor() {
            try {
                this.loading = true
                const { data = [] } = await tradeSvc.getTradeMonitor()
                this.tradeMonitorData = data
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        }
    },
    filters: {
        statusFilter: value => {
            return value === 0 ? '近期' : '延期'
        }
    }
}
</script>
<style lang="scss" scoped>
.arrive-monitor {
    ::v-deep .main {
        padding: 0;
        .com-card-header {
            padding: 0.2rem;
        }
    }
    grid-row-end: span 2;
    grid-column-end: span 2;
    &-main {
        height: 3.3rem;
        ::v-deep .el-table {
            .sku-title {
                display: flex;
                align-items: center;
                span:nth-child(1) {
                    padding: 0 0.08rem;
                    height: 0.24rem;
                    font-size: 0.12rem;
                    line-height: 0.24rem;
                    border-radius: 0.04rem;
                    color: #fff;
                    background: #69acf1;
                    margin-right: 0.05rem;
                    transform: scale(0.8);
                }
                span.delay {
                    background: #df5a68;
                }
                span:nth-child(2) {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 0.14rem;
                    color: #606266;
                }
            }
        }
        // .data-item {
        //     padding: 0 0.2rem;
        //     > p {
        //         line-height: 0.22rem;
        //         display: flex;
        //         align-items: center;
        //     }
        //     > p:nth-child(1) {
        //         span:nth-child(1) {
        //             padding: 0 0.08rem;
        //             height: 0.22rem;
        //             font-size: 0.12rem;
        //             line-height: 0.22rem;
        //             border-radius: 0.04rem;
        //             color: #fff;
        //             background: #df5a68;
        //             margin-right: 0.05rem;
        //             transform: scale(0.8);
        //         }
        //         span:nth-child(2) {
        //             flex: 1;
        //             overflow: hidden;
        //             text-overflow: ellipsis;
        //             white-space: nowrap;
        //             font-size: 0.14rem;
        //             color: #606266;
        //         }
        //     }
        //     > p:nth-child(2) {
        //         color: #a7b6c3;
        //         font-size: 0.14rem;
        //         justify-content: space-between;
        //     }
        // }
        // .data-item + .data-item {
        //     margin-top: 0.13rem;
        // }
    }
}
</style>
